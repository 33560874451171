var fn={};//initialize fn
$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});
$(function(){
	fn={
		Launch:function(){
			fn.App();
			fn.Slick();
			fn.Select();
			fn.MatchHeight();
			fn.SwipeBox();
			fn.Masonry();
			fn.JqueryUi();
			fn.validateContactForm();
      fn.validateForms();
		},
	App:function(){
		var offset = $('#load-more-testimonials').data('offset'),
		totalCount=$('#load-more-testimonials').data('total'),
		load = $('#fountain-bars').attr('src');
		$(".testimonial-wrapper .next").on("click",function(){
			var _this = $(this);
			var limit = _this.data('limit'), loadUrl = _this.data('url');
			var ct = _this.data('content');
			var ex = _this.data('exclude');
			$(this).html("Loading...");

			_this.attr('disabled','disabled');
			_this.html('<img src="'+load+'"/>');
			jQuery.ajax({
			    type: "post",
			    url: loadUrl,
			    cache: false,
					data: {limit:limit,offset:offset,exclude:ex},
			    success: function (data) {
						if(data.status=='success'){
								var html = data.html;
								var el = jQuery(html);
								jQuery(".grid").append(el).masonry( 'appended', el, true );
								offset = offset + limit;
								_this.attr('data-offset', offset);
								if(offset >= totalCount){
										_this.hide();
								}
							}
			    },
					complete:function(){
							_this.removeAttr('disabled');
							_this.html(ct);
					}
			});
		});

		$(document).on("click",".accordion-block .block .header",function(e){
				$(this).closest(".block").toggleClass("collapse-up").find(".toggle").slideToggle();
		});

		 $('[data-toggle="tooltip"]').tooltip();

		  //  review-form


          $(document).on("click",".total-reviews-header .write",function(){
            if($(this).attr("data-toggle-expanded") == "false"){

              $(".review-form").slideDown();
              $(this).attr("data-toggle-expanded","true");
            }else{
               $(".review-form").slideUp();
               $(this).attr("data-toggle-expanded","false");
            }
          });

          function fix_sidebar(){

           if($('.sidebar .fixed-bar').length >0 && $(window).width() > 991){
           		$(".fixed-bar").css('max-width',$('.fixed-bar').parent().width()+"px");
	            $('.sidebar .fixed-bar').affix({
				  offset: {
				    top: $(".sidebar .fixed-bar").offset().top,
				    bottom: function () {
				      return (this.bottom = $('footer.main').outerHeight(true) + ($('.recently-viewed').length > 0)?$('.recently-viewed').outerHeight(true)+300:300)
				    }
				  }
				});
			}
		}

		fix_sidebar();
		$(window).on("resize",function(){
			fix_sidebar();
		});


		$(document).on("click",'.dropdown-menu.destination .item .nav-tabs li a',function(e){e.stopPropagation();});

		// var navtabParent=$('.dropdown-menu.destination .item ');
		// if(navtabParent.find('.nav-tabs').height() > navtabParent.find('.tab-content').height()){
		// 	 navtabParent.find('.tab-content').css('min-height',navtabParent.find('.nav-tabs').height()+"px");
		// }else{
		// 	navtabParent.find('.nav-tabs').css('min-height',navtabParent.find('.tab-content').height()+"px");
		// }

		$(document).on("click","#itinerary-toggle",function(e){
			e.preventDefault();
			if($(document).find('#itinerary').length > 0){
				$("a[href='#itinerary']").trigger('click');
				$("html,body").animate({
					scrollTop:$("#itinerary").offset().top - 10
				},200);
			}
		});


			var lastId,
              topMenu = $(".affix-wrap"),
              topMenuHeight = topMenu.outerHeight()+15,
              // All list items
              menuItems = topMenu.find("a"),
              // Anchors corresponding to menu items
              scrollItems = menuItems.map(function(){
                var item = $($(this).attr("href"));
                if (item.length) { return item; }
              });

          // Bind click handler to menu items
          // so we can get a fancy scroll animation
              menuItems.click(function(e){

                var href = $(this).attr("href"),
                    offsetTop = href === "#" ? 0 : $(href).offset().top+1;
                $('html, body').stop().animate({
                    scrollTop: offsetTop
                }, 300);
                e.preventDefault();
              });

              // Bind to scroll
              $(window).scroll(function(){
                 // Get container scroll position
                 var fromTop = $(this).scrollTop();

                 // Get id of current scroll item
                 var cur = scrollItems.map(function(){
                   if ($(this).offset().top < fromTop)
                     return this;
                 });
                 // Get the id of the current element
                 cur = cur[cur.length-1];
                 var id = cur && cur.length ? cur[0].id : "";

                 if (lastId !== id) {
                     lastId = id;
                     // Set/remove active class
                     menuItems
                       .parent().removeClass("active")
                       .end().filter("[href='#"+id+"']").parent().addClass("active");
                 }
              });
      	if($(".btn-fix-departure").length > 0){
      		$(".carousel-caption .btn-fix-departure").on("click",function(){
      			var target=$(this).attr("href");
      			$("html,body").animate({
      				scrollTop:$(target).offset().top
      			},300)
      		});
      	}
				$('.map')
					.click(function(){
							$(this).find('iframe').addClass('clicked')})
					.mouseleave(function(){
							$(this).find('iframe').removeClass('clicked')});

	},
	findTrips: function(){
			$('#find_activity').prop('disabled', true);
					console.log("destination");
			$('#find_destination').change(function(e) {
					e.preventDefault();
					console.log("here");
					var val = $(this).val();
					if (val != '') {
							$('#find_activity').html('<option>Loading...</option>');
							$('#find_activity').prop('disabled', false);
							fn.findActivity();
					} else {
							$('#find_activity').prop('disabled', true);
							return false;
					}
			});
			$('#budget-selection').on('change',function(){
					var selected = $(this).val();
					var min=$(this).data('min'),max=$(this).data('max');
					if(selected == 'below'){
							max = 500;
					}else if(selected == 'above'){
							min = 3000;
					}else{
							var arr = selected.split("-");
							min = arr[0];
							max = arr[1];
					}
					$('#min-price').val(min);
					$('#max-price').val(max);
			});
	},
	findActivity:function() {
			var url = $('#find_destination').data('action');
			var val = $('#find_destination').find('option:selected').val();
			if (val != '') {
					$.ajax({
							url: url,
							type: 'POST',
							data: {
									slug: val
							},
							success: function(response) {
									var resData = response.activity;
									var selData = $('#find_activity').data('select');

									$('#find_activity').html(resData);
									if (response.status == 'failed') {
											$('#find_activity').prop('disabled', true);
									} else {
											$('#find_activity').prop('disabled', false);
									}

									if(selData !=""){
											$('#find_activity option[value='+selData+']').prop('selected',true);
									}
									$('#find_activity').selectpicker('refresh');
							}
					});
			}
	},
	Slick:function(){
		$(".banner-slider").slick({
			  centerMode: true,
			  centerPadding: '11%',
			  slidesToShow: 1,
			  prevArrow:"<i class='banner-arrow arrow-left'>prev</i>",
			  nextArrow:"<i class=' banner-arrow arrow-right'>Next</i>",
			  responsive: [
			    {
			      breakpoint: 991,
			      settings: {

			        centerMode: false,
			        centerPadding: '40px',
			        slidesToShow: 1
			      }
			    },
			    {
			      breakpoint: 767,
			      settings: {

			        centerMode: false,
			        centerPadding: '40px',
			        slidesToShow: 1
			      }
			    }
			  ]
		});

		$(".special-slider .row").slick({
			 slidesToShow: 3,
			 prevArrow:"<i class='zmdi zmdi-chevron-left banner-arrow arrow-left'></i>",
			  nextArrow:"<i class='zmdi zmdi-chevron-right banner-arrow arrow-right'></i>",
			  responsive: [
			    {
			      breakpoint: 991,
			      settings: {

			        centerMode: true,
			        centerPadding: '40px',
			        slidesToShow: 2
			      }
			    },
			    {
			      breakpoint: 660,
			      settings: {

			        centerMode: false,
			        centerPadding: '40px',
			        slidesToShow: 1
			      }
			    }
			  ]
		});
		$(".blog .blog-wrapper .row").slick({
			 slidesToShow: 3,
			 prevArrow:"<i class='zmdi zmdi-chevron-left banner-arrow arrow-left'></i>",
			  nextArrow:"<i class='zmdi zmdi-chevron-right banner-arrow arrow-right'></i>",
			  responsive: [
			    {
			      breakpoint: 991,
			      settings: {

			        centerMode: true,
			        centerPadding: '40px',
			        slidesToShow: 2
			      }
			    },
			    {
			      breakpoint: 660,
			      settings: {

			        centerMode: false,
			        centerPadding: '40px',
			        slidesToShow: 1
			      }
			    }
			  ]
		});



		 $('.popular-trips-slider .slider-for').slick({
		  slidesToShow: 1,
		  slidesToScroll: 1,
		  arrows: false,
		  fade: true,
		  asNavFor: '.slider-nav'
		});
		$('.popular-trips-slider .slider-nav').slick({
			  slidesToShow:4,
			  slidesToScroll: 1,
			  variableWidth: true,
			  centerPadding: '40px',
			   prevArrow:"<i class='zmdi zmdi-long-arrow-left banner-arrow arrow-left'></i>",
		 		 nextArrow:"<i class='zmdi zmdi-long-arrow-right banner-arrow arrow-right'></i>",
			  asNavFor: '.slider-for',
			  dots: false,
			  centerMode: true,
			  focusOnSelect: true,
			  responsive: [
			   {
			      breakpoint: 991,
			      settings: {
			    	variableWidth: false,
			        centerMode: true,

			        slidesToShow: 3
			      }
			    },
			    {
			      breakpoint: 768,
			      settings: {

			        centerMode: false,
			 		variableWidth: false,
			        slidesToShow: 2
			      }
			    },
			    {
			      breakpoint: 480,
			      settings: {

			        centerMode: false,
					variableWidth: false,
			        slidesToShow: 1
			      }
			    }
			  ]
		});
		$('.recently-viewed .row').slick({
			 slidesToShow: 4,
			 prevArrow:"<i class='zmdi zmdi-chevron-left banner-arrow arrow-left'></i>",
			  nextArrow:"<i class='zmdi zmdi-chevron-right banner-arrow arrow-right'></i>",
			  responsive: [
			    {
			      breakpoint: 991,
			      settings: {

			        centerMode: true,
			        centerPadding: '40px',
			        slidesToShow: 3
			      }
			    },
			    {
			      breakpoint: 660,
			      settings: {

			        centerMode: false,
			        centerPadding: '40px',
			        slidesToShow: 2
			      }
			    },
			    {
			      breakpoint: 480,
			      settings: {

			        centerMode: false,
			        centerPadding: '40px',
			        slidesToShow: 1
			      }
			    }
			  ]
		});

		// lightbox.option({
		// 	'resizeDuration': 200,
		// 	'wrapAround': true
		// })
	},
	Select:function(){
		$('.select-picker').selectpicker({
		  size: 10
		});

	},
	MatchHeight:function(){
		$(".popular-trips-slider .slider-for .item").matchHeight();
		$(".blog-wrapper .description .title").matchHeight();
		$(".blog-wrapper .description .desc").matchHeight();
		$(".card .description .title").matchHeight();
		$(".card .description .desc").matchHeight();
	},
	SwipeBox:function(){
		$( '.gallery-swipe' ).swipebox();
		$(".photos a").swipebox();
	},
	Masonry:function(){
		$('.grid').masonry({
		  // options
		  itemSelector: '.grid-item',

		});
	},
	JqueryUi:function(){
		$( "#slider-range" ).slider({
			range: true,
			min: 0,
			max: 500,
			values: [ 75, 300 ],
			slide: function( event, ui ) {
				$( "#amount" ).val( "$" + ui.values[ 0 ] + " - $" + ui.values[ 1 ] );
			}
		});
		 $( "#amount" ).val( "$" + $( "#slider-range" ).slider( "values", 0 ) + " - $" + $( "#slider-range" ).slider( "values", 1 ) );


		  $(".date-picker").datepicker();
		  $('.date-select .trigger-cal').click(function(){
              $(this).siblings('input').focus();
          });
	},
	//CONTACT FORM
	validateContactForm : function(){
		$('#contact-form').validate({
				ignore: [],
				rules: {
						name: { required: true },
						email: { required: true, email: true },
						phone: { minlength: 7 },
						subject: { required: true },
						message: { required: true },
						'g-recaptcha-response': { required: true }
				},
				messages: {
						name: { required: 'Please enter your name.' },
						email: { required: 'Please enter the email address', email: 'Please enter a valid email address.' },
						phone: { minlength: 'Phone number must be minimum of 7 digits.' },
						/*country: {required: 'Please enter your country.'},*/
						subject: { required: 'Please enter the subject of the email' },
						message: { required: 'Please enter the message of the email' },
						'g-recaptcha-response': { required: 'Captcha is required' }
				},
				highlight: function(element) {
						$(element).addClass('msg-danger');
				},
				unhighlight: function(element) {
						$(element).removeClass('msg-danger');
				},
				submitHandler: function(form) {
						//$(form).find('button[type=submit]').attr('disabled','disabled');
						form.submit();
				}
		});
	},
	loadCountries:function(container,url){
			/** loads countries from json */
			var selected = "";
			if($(container).data('selected')!==undefined){
					selected = $(container).data('selected');
			}
			$.getJSON(url, function(data) {
					var items = '';
					$.each(data, function(key, val) {
							items += "<option value='" + val.name + "' data-code='" + val.code + "' "+(selected==val.name?"selected='selected'":"")+">" +
													val.name +
											"</option>";
					});
					$(container).append(items);
					if($(window).width() > 767){
					 $('.country-selectpicker').selectpicker('refresh');
					}
			});
	},
	countrySelection: function(_countryId,_stateId){
			fn.countrySelected(_countryId, _stateId);
			$(document).on('change',_countryId, function(){
					fn.countrySelected(_countryId, _stateId);
			});
	},
	countrySelected: function(elm,stateElm){
			var _loader = $("#loaders").data('loading');
			var cid = $(elm).val();
			var url = $(elm).data('url');
			var sel = $(stateElm).data('val');
			if(sel == undefined || !sel){
					sel=null;
			}

			if(cid){
					_state = $(stateElm);
					if(_state.siblings(".cntry-loader").length ==0){
							_state.parent().append("<img src='"+_loader+"' class='cntry-loader'/>");
					}
					$.ajax({
							url:url,
							data:{cid:cid,sid:sel},
							type:'post',
							success:function(data){
									if(data.status == "success"){
											var statehtml = data.html;
											_state.html($(statehtml).html());
											$('.selectpicker').selectpicker('refresh');
									}
							},
							complete:function(){
								_state.siblings(".cntry-loader").remove();
							}
					});
			}
	},
  submitSubscription:function(form) {
			$('#subscription_btn').html('<img src="'+$(form).data('loader')+'"/>');
			$('#subscription_btn').attr('disabled','disabled');
			var ct = $('#subscription_btn').data('content');
			$.ajax({
					data: $(form).serialize(),
					url: $(form).attr('action'),
					type: 'post',
					dataType:'JSON',
					success: function(response) {
							if (response) {
									//var response = $.parseJSON(data);
									if (response.status == 'success') {
											if(response.redirect_url){
													window.location.href = response.redirect_url;
											}
											$(form)[0].reset();
											$(form).find('.is-success').each(function(){
													$(this).removeClass('is-success');
											});

									} else if (response.status == 'error') {
											if(response.error_msg){
													var elm = $(form).find('input[name=email]');
													elm.removeClass('is-success').addClass('has-error');
													elm.attr('data-original-title',"This email has already been subscribed!");
													elm.tooltip();
													elm.focus();
											}else{
													alert(response.message);
											}
									}
							} //data close

					}, //success close
					complete: function(){
							$('#subscription_btn').html(ct);
							$('#subscription_btn').removeAttr('disabled');
					}
			}); //ajax close
	},
	submitEnquiry:function(form) {
			var _btn = $('#package-enquiry button[type=submit]');
			_btn.html('Sending...');
			_btn.attr('disabled','disabled');
			$.ajax({
					url: $(form).attr('action'),
					data: $(form).serialize(),
					type: 'POST',
					success: function(response) {
							var icon = 'warning';
							var type = 'danger';
							if (response.status == 'success') {
									icon = 'check';
									type = 'success';
									$(form)[0].reset();
									var content = '<div class="alert alert-' + type + ' alert-effect in">' + '<i class="fa fa-' + icon + '"></i> ' + response.message + '</div>';
									$('#package-enquiry').html(content);
									$('html,body').animate({
											scrollTop: $('#package-enquiry').offset().top
									},600);
							}
					},
					complete:function(){
							_btn.html('Send');
							_btn.removeAttr('disabled');
							fn.refreshCaptcha();
					},
					error :function( jqXhr ) {

							//process validation errors here.
							$errors = jqXhr.responseJSON; //this will get the errors response data.
							//show them somewhere in the markup

							$.each( $errors, function( key, value ) {
									// console.log(key+" "+value);
									var _inp = $(form).find('input[name='+key+']');
									// console.log(_inp);
									var _errelm = _inp.siblings('label.error');
									// console.log(_errelm);
									if(_errelm.length == 0){
											// console.log(';here');
											var div = document.createElement('label');
											div.className="error"; div.id=_inp.attr('id')?_inp.attr('id'):_inp.attr('name');
											div.innerHTML = value;
											_inp.after(div);
									}else{
											// console.log('there is an elm');
											_errelm.html(value);
											_errelm.show();
									}
							});
					}
			});
	},
	saveReview:function(form) {
			var _btn = $(form).find('button.submit-btn');
			var _loader = $('#fountain-bars').attr('src');
			_btn.html('Submitting...');
			_btn.attr('disabled','disabled');
			$.ajax({
					data: $(form).serialize(),
					url: $(form).attr('action'),
					type: 'post',
					dataType:'JSON',
					success: function(response) {
							if (response) {
									//var response = $.parseJSON(data);
									if (response.status == 'success') {
											if(response.isAdmin==true){
													var allData = response.html;
													var content = $(allData).find('ul').html();
													$('.review-lists ul').prepend(content);
													// console.log(content);
													$('#review-form')[0].reset();
													$('.img-review').css('background','url('+$('.img-review').data('src')+')  no-repeat center');
													$('.review-form').prepend('<div class="alert alert-success alert-effect in">'+
																													'<a class="close" data-dismiss="alert" aria-label="Close"><i class="fa fa-times"></i></a>'+
																													'<i class="fa fa-warning"></i> Review Added Successfully!'+
																													'</div>');
													var img = $('.review-form .upload-image-wrap').data('default');
													$('.upload-image-wrap .upload-image .img').html("<img src='"+img+"'/>")
													$('#uploadMultipleFile .qq-upload-list').html('');
													$('#review-images').html('');
													setTimeout(function(){
															$('html,body').animate({
																	scrollTop: $('#review_'+revId).offset().top-100
															});
													},200);
											}else{
													$('#review-form').html(response.html);
													setTimeout(function(){
															$('html,body').animate({
																	scrollTop: $('.review-form').offset().top-100
															});
													}, 200);
											}
									} else if (response.status == 'error') {
											if(response.error_msg){

											}else{
													alert(response.message);
											}
									}
							} //data close

					}, //success close
					complete: function(){
							_btn.html('Submit');
							_btn.removeAttr('disabled');
							fn.refreshCaptcha();
					},
					error :function( jqXhr ) {

							//process validation errors here.
							$errors = jqXhr.responseJSON; //this will get the errors response data.
							//show them somewhere in the markup


							$.each( $errors, function( key, value ) {
									// console.log(key+" "+value);
									var _inp = $(form).find('input[name='+key+']');
									// console.log(_inp);
									var _errelm = _inp.siblings('label.error');
									// console.log(_errelm);
									if(_errelm.length == 0){
											// console.log(';here');
											var div = document.createElement('label');
											div.className="error"; div.id=_inp.attr('id')?_inp.attr('id'):_inp.attr('name');
											div.innerHTML = value;
											_inp.after(div);
									}else{
											// console.log('there is an elm');
											_errelm.html(value);
											_errelm.show();
									}
							});
					}
			}); //ajax close
	},
  createElevationChart: function(elevData){
     var chart = null;

     if(typeof Morris=='object' && typeof elevData=='object'){
         chart = Morris.Line({
             element: 'elevation-chart',
             data:elevData,
             xkey:'day',
             ykeys:['height'],
             labels: ['Height (in metres)'],
             parseTime: false,
             pointSize: 2,
             hideHover: 'auto',
             resize: true,
             opacity:0.5,
             lineColors: ['#E59400', '#999966', '#cc6666', '#663333'],
             gridLineColor: '#555',
         });
     }
     return chart;
 },
  loadReviews:function(){
      var offset = 0,totalCount=$('#load-more-reviews').data('total');

      $('#load-more-reviews').on('click',function(){
          var _this = $(this);
          var load = $('#fountain-bars').attr('src');
          var limit = _this.data('limit'), loadUrl = _this.data('url'), pid = _this.data('pid')
          var ct = _this.data('content');
          offset = offset + limit;
          _this.attr('disabled','disabled');
          _this.html('<img src="'+load+'"/>');

          $.ajax({
              url:loadUrl,
              data:{limit:limit,offset:offset,pid:pid},
              type:'post',
              dataType:'json',
              success:function(data){
                  if(data.status=='success'){
                      var html = data.html;
                      var el = jQuery(html);
                      console.log(el);
                      $('.review-lists ul').append(el);
                      $('.cur-rev-count').html(offset+data.count);
                      if(limit+offset >= totalCount){
                          _this.hide();
                      }
                      //console.log('total='+totalCount+' limit&offset='+limit+'>>'+offset)
                  }
              },
              complete:function(){
                  _this.removeAttr('disabled');
                  _this.html(ct);
              }
          })
      });
  },
  refreshCaptcha:function(){
      var captcha = $('.captcha-img');
      var config = captcha.data('refresh-config');
      captcha.css('opacity','0.1');
      $.ajax({
          method: 'POST',
          url: captcha.attr('data-get')+"/" + config,
      }).done(function (response) {
          captcha.prop('src', response);
          captcha.css('opacity','1');
      });
  },
  defineValidateUserEmail:function(formELm,id){
    var checkUrl = $(formELm).data('checkemail');
    $(formELm+' :input[name="email"]').change(function() {
        $(this).removeData("previousValue");
    });

    $.validator.addMethod("validateUserEmail", function(value, element){
        var inputElem = $(formELm+' :input[name="email"]'),
            data = { "email" : inputElem.val() },
            eReport = ''; //error report
        if(id != undefined){
          data.user_id = id;
        }

        var result = false;
        $.ajax({
            url: checkUrl,
              type: "post",
              dataType:'json',
              async:false,
              data: data,
              beforeSend:function(){
                var fb = $("#loaders").data('loading');
                var loader = $(formELm).find('#email-block .email-check-loader');
                if($('#email-error').length>0){
                  $('#email-error').hide();
                }
                if(loader.length>0){
                  loader.show();
                }else{
                  $(formELm).find('#email-block').append("<img src='"+fb+"' class='email-check-loader'/>");
                }
              },
              success:function(data){
                var elm = $('#email-block');
                var loader = elm.find('.email-check-loader');
                var msg = "The email address is already taken!";
                loader.hide();
                if(data.status=="true"){
                  $('#email-error').hide();
                  result= true;
                }else if(data.status=='false'){
                  if($('#email-error').length>0){
                    $('#email-error').html(msg);
                  }else{
                    var div = document.createElement('label');
                    div.className="has-error-txt error"; div.setAttribute("for","email"); div.id="email-error";
                    div.innerHTML = msg;
                    elm.append(div);
                  }
                  result= false;
                }else{
                  alert('Something went wrong!');
                }
              },
              error: function(xhr, textStatus, errorThrown)
            {
                alert('ajax loading error... ... '+url + query);
                result= false;
            }
        });
      return result;

    }, '');
  },
  tellFriend:function(form) {
    console.log('hello')
    return 'hello';
      var _btn = $('#tell-a-friend .btn-send');
      console.log(_btn.length);
      _btn.html('Sending...');
      _btn.attr('disabled','disabled');
      $.ajax({
          url: $(form).attr('action'),
          data: $(form).serialize(),
          type: 'POST',
          success: function(response) {

              var icon = 'warning';
              var type = 'danger';
              if (response.status == 'success') {
                  icon = 'check';
                  type = 'success';
                  $(form)[0].reset();
                  var content = '<div class="alert alert-' + type + ' alert-effect in">' + '<i class="fa fa-' + icon + '"></i> ' + response.message + '</div>';
                  $('#tell-a-friend .modal-body').html(content);
                  _btn.hide();
              }
          },complete:function(){
              _btn.html('Send');
              _btn.removeAttr('disabled')
          }
      });
  },
  validateForms: function(){
      jQuery.validator.addMethod("multiemail", function(value, element) {
          if (this.optional(element)) // return true on optional element
              return true;
          var emails = value.split(/[;,]+/); // split element by , and ;
          valid = true;
          for (var i in emails) {
              value = emails[i];
              valid = valid && jQuery.validator.methods.email.call(this, $.trim(value), element);
          }
          return valid;
      }, jQuery.validator.messages.email);
      //SUBSCRIBE
      $('#subscribe-us').validate({
          rules: {
              email: { required: true,email:true},
          },
          messages: {
              email: { required: "Please enter your email address.", },
          },
          errorPlacement: function (error, element) {
              $('#subscribe-us').append(error);
          },
          /*success: function (label, element) {
              $(element).attr('data-original-title','');
              $(element).tooltip();
          },*/
          highlight: function(element) {
              $(element).removeClass('is-success').addClass('has-error');
          },
          unhighlight: function(element) {
              $(element).removeClass('has-error').addClass('is-success');
          },
          submitHandler: function(form) {
              fn.submitSubscription(form);
          }
      });

      //CONTACT FORM
      $('#contact-form').validate({
          ignore: [],
          rules: {
              name: { required: true },
              email: { required: true, email: true },
              phone: { minlength: 7 },
              subject: { required: true },
              message: { required: true },
              'g-recaptcha-response': { required: true }
          },
          messages: {
              name: { required: 'Please enter your name.' },
              email: { required: 'Please enter the email address', email: 'Please enter a valid email address.' },
              phone: { minlength: 'Phone number must be minimum of 7 digits.' },
              /*country: {required: 'Please enter your country.'},*/
              subject: { required: 'Please enter the subject of the email' },
              message: { required: 'Please enter the message of the email' },
              'g-recaptcha-response': { required: 'Captcha is required' }
          },
          highlight: function(element) {
              $(element).addClass('msg-danger');
          },
          unhighlight: function(element) {
              $(element).removeClass('msg-danger');
          },
          submitHandler: function(form) {
              //$(form).find('button[type=submit]').attr('disabled','disabled');
              fn.tellFriend(form);
          }
      });

      //REVIEW FORM
      $('#review-form').validate({
          ignore:[],
          rules: { 'author': { required: true },
              'email': { required: true, email: true },
              'title': { required: true, },
              'content': { required: true, minlength: 10, },
              captcha_text: { required: true }
          },
          messages: {
              'author': { required: 'Please enter your Full Name.' },
              'email': { required: 'Please enter your Email Address.' },
              'title': { required: 'Please enter Review Title.' },
              'content': { required: 'Please enter the Review.' },
               captcha_text: { required: 'Captcha is required' }
          },
          errorPlacement: function (error, element) {
              if($(element).attr('id')=='g-recaptcha-response'){
                  $(element).parents('.form-group').append(error);
              }else{
                  error.insertAfter(element);
              }
          },
          highlight: function(element) {
              $(element).addClass('msg-danger');
          },
          unhighlight: function(element) {
              $(element).removeClass('msg-danger');
          },
          submitHandler: function(form) {
              fn.saveReview(form);
          }
      });

      //TELL A FRIEND FORM
      $('#tell-friend-form').validate({
          ignore: [],
          rules: {
              name: { required: true },
              email: { required: true, email: true },
              friend_email: { required: true,multiemail: true },
              subject: { required: true },
              message: { required: true },
              captcha_text: { required: true }
              //'g-recaptcha-response':{required:true}
          },
          messages: {
              name: { required: 'Please enter your full name.' },
              email: { required: 'Please enter the email address', email: 'Please enter a valid email address.' },
              friend_email: { multiemail: 'Emails should be valid.' },
              subject: { required: 'Please enter the subject of the email' },
              message: { required: 'Please enter the message of the email' },
              'g-recaptcha-response': { required: 'Captcha is required' },
              captcha_text: { required: 'Captcha is required' }
          },
          //errorElement: 'div',
          //errorLabelContainer: '.contact-errors',
          highlight: function(element) {
              //$('.contact-errors').addClass('alert alert-danger alert-effect in');
              //$('.contact-errors a.close').show('');
              $(element).addClass('msg-danger');
          },
          unhighlight: function(element) {
              $(element).removeClass('msg-danger');
          },
          submitHandler: function(form) {
            alert('ello');
            fn.tellFriend(form);
              //form.submit();
          }
      });

      //BOOKING FORM
      $('#booking-form').validate({
          ignore: [],
          rules: {
              'package_id': { required: true },
              'start_date': { required: true },
              'end_date': { required: true },
              'pax': { required: true,number:true},
              'first_name': { required: true,maxlength:200 },
              'last_name': { required: true,maxlength:200 },
              'email': { required: true, email: true },
              'address': { required: true },
              'city': { required: true },
              'country': { required: true },
              'emergency_name': { required: true },
              'emergency_phone': { required: true},
              'g-recaptcha-response': { required: true }
          },
          messages: {
              'package_id': {required: 'Please select a package.'},
              'start_date': {required: 'Please select the start date of your trip.'},
              'end_date': {required: 'Please select the end date of your trip.'},
              'pax': {required: 'Please select the number of passengers.'},
              'first_name': {required: 'Please enter your first name.'},
              'last_name': {required: 'Please enter your last name.'},
              'email': {required: 'Please enter your email address.',email:'Please enter valid email address.'},
              'address': {required: 'Please enter your address.'},
              'city': {required: 'Please enter your city.'},
              'country': {required: 'Please select your country.'},
              'emergency_name': {required: 'Please enter the emergency contact name.'},
              'emergency_phone': {required: 'Please enter the emergency contact number.'},
              'g-recaptcha-response': { required: 'Captcha is required' }
          },
          errorPlacement: function (error, element) {
              if($(element).attr('id')=='g-recaptcha-response' || $(element).hasClass('selectpicker')){
                  $(element).parents('.form-group').append(error);
              }else{
                  error.insertAfter(element);
              }
          },
          highlight: function(element) {
              $(element).addClass('msg-danger');
          },
          unhighlight: function(element) {
              $(element).removeClass('msg-danger');
          },
          submitHandler: function(form) {
              /*$(form).find('button[type=submit]').attr('disabled','disabled');
              form.submit();*/
               var _btn = $(form).find('button[type=submit]');
              _btn.attr('disabled','disabled');
              if($('#pay').val()==1){
                  $.ajax({
                      url:$(form).attr('action'),
                      data:$(form).serialize(),
                      type:'post',
                      success: function(data){
                          if(data.status='success'){
                              $('#amount').val(data.price);
                              $('#return_url').val(data.route);
                              $('#custom-field').val(data.id);
                              $('#paypal-form').submit();
                          }else{

                          }
                      },
                      complete:function(){
                          _btn.html('Send');
                          _btn.removeAttr('disabled');
                          grecaptcha.reset();

                      },
                      error :function( jqXhr ) {
                          //process validation errors here.
                          $errors = jqXhr.responseJSON; //this will get the errors response data.
                          //show them somewhere in the markup

                          $.each( $errors, function( key, value ) {
                              console.log(key+" "+value);
                              var _inp = $(form).find('input[name='+key+']');
                              console.log(_inp);
                              var _errelm = _inp.siblings('label.error');
                              console.log(_errelm);
                              if(_errelm.length == 0){
                                  console.log(';here');
                                  var div = document.createElement('label');
                                  div.className="error"; div.id=_inp.attr('id')?_inp.attr('id'):_inp.attr('name');
                                  div.innerHTML = value;
                                  _inp.after(div);
                              }else{
                                  console.log('there is an elm');
                                  _errelm.html(value);
                                  _errelm.show();
                              }
                          });
                      }
                  });
              }else{
                  form.submit();
              }
          }
      });

      var validate_query=false;
      if($('#customize-form').length >0 && $('#msg').length>0 && $('#msg').data('validate')==true){
          validate_query=true;
      }
      //CUSTOMIZE TRIP
      $('#customize-form').validate({
          ignore: [],
          rules: {
              first_name: { required: true },
              last_name: { required: true },
              email: { required: true, email: true },
              phone: { minlength: 7 },
              trip_name: { required: (!validate_query?true:false) },
              trip_duration: { required: (!validate_query?true:false) },
              trip_price: { required: (!validate_query?true:false) },
              message: { required: (validate_query?true:false) },
              'g-recaptcha-response': { required: true }
          },
          messages: {
              first_name: { required: 'Please enter your first name.' },
              last_name: { required: 'Please enter your last name.' },
              email: { required: 'Please enter the email address', email: 'Please enter a valid email address.' },
              phone: { minlength: 'Phone number must be minimum of 7 digits.' },
              trip_name: { required: 'Please enter the trip name' },
              trip_duration: { required: 'Please select the trip duration' },
              trip_price: { required: 'Please select the trip price range' },
              message: { required: 'Please enter the details about the trip you want.' },
              'g-recaptcha-response': { required: 'Captcha is required' }
          },
          highlight: function(element) {
              $(element).addClass('msg-danger');
          },
          unhighlight: function(element) {
              $(element).removeClass('msg-danger');
          },
          submitHandler: function(form) {
              $(form).find('button[type=submit]').attr('disabled','disabled');
              form.submit();
          }
      });

      //SEARCH
      $('#search').validate({
          rules: {
              keyword: { required: true},
          },
          messages: {
              keyword: { required: "Please enter a keyword to search.", },
          },
          errorPlacement: function (error, element) {
              $(element).attr('data-original-title',$(error).text());
              $(element).tooltip();
          },
          success: function (label, element) {
              $(element).attr('data-original-title','');
              $(element).tooltip();
          },
          highlight: function(element) {
              $(element).removeClass('is-success').addClass('has-error');
          },
          unhighlight: function(element) {
              $(element).removeClass('has-error').addClass('is-success');
          }
      });

      //PACKAGE ENQUIRY
      $('#enquiry-form').validate({
          ignore: [],
          rules: {
              name: { required: true},
              email: { required: true, email: true },
              phone: { minlength: 7 },
              message: { required: true },
              'g-recaptcha-response':{required:true}
          },
          messages: {
              name: { required: 'Please enter your full name.' },
              email: { required: 'Please enter the email address', email: 'Please enter a valid email address.' },
              phone: { minlength: 'Phone number must be minimum of 7 digits.' },
              message: { required: 'Please enter the message of the enquiry.' },
              'g-recaptcha-response':{required:'Please verify that you are human.'}
          },
          errorPlacement: function (error, element) {
              if($(element).attr('id')=='g-recaptcha-response'){
                  $(element).parents('.form-group').append(error);
              }else{
                  error.insertAfter(element);
              }
          },
          highlight: function(element) {
              $(element).addClass('msg-danger');
          },
          unhighlight: function(element) {
              $(element).removeClass('msg-danger');
          },
          submitHandler: function(form) {
              fn.submitEnquiry(form);
          }
      });

      //USER LOGIN
      $('#user-login').validate({
        rules:{
          email:{required:true, email:true},
          password:{required:true}
        },
        messages:{
          email:{required:"Please enter your email address!"},
          password:{required:"Please enter your password!"},
        },
        errorPlacement: function (error, element) {
            $(element).parents('.form-group').append(error);
        },
        highlight: function(element) {
            $(element).removeClass('is-success').addClass('has-error');
        },
        unhighlight: function(element) {
            $(element).removeClass('has-error').addClass('is-success');
        },
      });

      //REGISTER
      if($('#user-register').length>0){
        fn.defineValidateUserEmail('#user-register');
        $('#user-register').validate({
          onkeyup:false,
          rules:{
            first_name:{required:true},
            last_name:{required:true},
            email:{required:true, email:true,validateUserEmail:true},
            password:{required:true, minlength:6},
            password_confirmation:{required:true,equalTo:'#register_password'}
          },
          messages:{
            first_name:{required:"Please enter your first name!"},
            last_name:{required:"Please enter your last name!"},
            email:{required:"Please enter your email address!",validateUserEmail:"The email address is already taken!"},
            password:{required:"Please enter your password!"},
            password_confirmation:{required:"Please enter the password again!",equalTo:"This value should match with the password!"}
          },
          highlight: function(element) {
              $(element).removeClass('is-success').addClass('has-error');
          },
          unhighlight: function(element) {
              $(element).removeClass('has-error').addClass('is-success');
          },
        });
      }

      //EDIT PROFILE
      if($('#edit_profile').length>0){
        fn.defineValidateUserEmail('#edit_profile',$('#user_id').val());
        $('#edit_profile').validate({
          onkeyup:false,
          rules:{
            first_name:{required:true},
            last_name:{required:true},
            email:{required:true, email:true,validateUserEmail:true},
          },
          messages:{
            first_name:{required:"Please enter your first name!"},
            last_name:{required:"Please enter your last name!"},
            email:{required:"Please enter your email address!",validateUserEmail:"The email address is already taken!"},
          },
          highlight: function(element) {
              $(element).removeClass('is-success').addClass('has-error');
          },
          unhighlight: function(element) {
              $(element).removeClass('has-error').addClass('is-success');
          },
        });
      }

      $('#edit_password').validate({
          rules:{
            password:{required:true, minlength:6},
            password_confirmation:{required:true,equalTo:'#original_password'}
          },
          messages:{
            password:{required:"Please enter your password!"},
            password_confirmation:{required:"Please enter the password again!",equalTo:"This value should match with the password!"}
          },
          highlight: function(element) {
              $(element).removeClass('is-success').addClass('has-error');
          },
          unhighlight: function(element) {
              $(element).removeClass('has-error').addClass('is-success');
          },
      });

  },
  loadPriceRange:function(){
      if($( "#slider-range" ).length>0){
          var minRange = parseInt($("#slider-range").data('minrange'));
          var maxRange = parseInt($("#slider-range").data('maxrange'));
          var minVal = parseInt($("#slider-range").data('min'));
          var maxVal= parseInt($("#slider-range").data('max'));
          $( "#slider-range" ).slider({
                range: true,
                min: minRange,
                max: maxRange,
                values: [ minVal, maxVal ],
                slide: function( event, ui ) {
                  $( "#amount" ).val( "$" + ui.values[ 0 ] + " - $" + ui.values[ 1 ] );
                }
          });
          $( "#amount" ).val( "$" + $( "#slider-range" ).slider( "values", 0 ) +
                " - $" + $( "#slider-range" ).slider( "values", 1 ) );
      }
  },
  directionHover:function(){

      $(' .da-thumbs > li ').each( function() { $(this).hoverdir(); } );

  }
}

	$(document).ready(function(){
		fn.Launch();

		if ($('#find_destination').length > 0) {
				fn.findTrips();fn.findActivity();
		}

		if ($('#contact-form').length > 0) {
				fn.loadCountries('#country_name',$('#contact-form').data('jsonurl'));
		}
		if($('#load-more-testimonials').length >0 ){
				fn.loadTestimonials();
		}

		if($('#load-more-reviews').length >0 ){
				fn.loadReviews();
		}

		if($('#booking-form').length > 0){
				fn.bookingForm();
		}

		if($('#select-from-city').length >0 ){
			fn.fromSelected();
		}

	});

});
